<template>
	<Home />
</template>

<script>
import Home from '../modules/home/Home.vue'

export default {
	name: 'HomeView',
	components: {
		Home,
	},
}
</script>
